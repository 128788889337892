import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  Grid,
  CssBaseline,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import Latex from "./Latex";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
//import { fetchPosts } from "../redux/reducers/postReducer";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import {
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { fetchPosts } from "../redux/reducers/postReducer";
import { fetchCompetitions } from "../redux/reducers/recentCompetitionsReducer";
import MarkdownMathRenderer from "./MarkdownMathRenderer";

const CompetitionsPosts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { competitionId } = useParams();
  const { isLoggedIn, loginMethod, googleUser, customUser } = useSelector(
    (state) => state.user
  );
  const [commentText, setCommentText] = useState("");
  const [latexResult, setLatexResult] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [attachedFile, setAttachedFile] = useState(null);

  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [userId, setUserId] = useState("");
  const [open, setOpen] = useState(false); // State to control the popup
  const [competitions, setCompetitions] = useState("");
  const [markDownResult, setMarkDownResult] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editSolution, setEditSolution] = useState("");

  const postUrl = `${window.location.origin}/competition/${competitionId}`;

  const isAdmin =
    // Handle googleUser.role (can be array or string)
    (googleUser?.role &&
      (Array.isArray(googleUser.role)
        ? googleUser.role.includes("Admin")
        : googleUser.role === "Admin")) ||
    // Handle customUser.role (can be array or string)
    (customUser?.role &&
      (Array.isArray(customUser.role)
        ? customUser.role.includes("Admin")
        : customUser.role === "Admin"));

  useEffect(() => {
    if (loginMethod === "google") {
      setUserId(googleUser.userId);
    } else if (loginMethod === "custom") {
      setUserId(customUser.userId);
    }

    console.log("is admin : ", isAdmin);

    if (competitionId) {
      getCompetitionById(competitionId);
    }
  }, [competitionId, customUser, googleUser, loginMethod]);

  const getCompetitionById = async (competitionId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Competition/GetCompetition/${competitionId}`
      );
      if (response.status === 200) {
        console.log(response.data);

        setCompetitions(response.data);
      } else {
        console.error(
          "Failed to fetch post: Unexpected status code",
          response.status
        );
      }
    } catch (error) {
      console.error("Failed to fetch post:", error.message);
    }
  };

  useEffect(() => {
    // Check if post and post.likes are available before calculating likes
    if (competitions && Array.isArray(competitions.likes)) {
      const postlikeCount = competitions.likes.filter(
        (like) => like.likeStatus === true
      ).length;
      setLikeCount(postlikeCount);
    }
  }, [competitions]);

  const [comments, setComments] = useState(competitions.comments || []);

  const handleCommentChange = (event) => {
    const value = event.target.value;
    setCommentValue(value);

    if (value.includes("$")) {
      setMarkDownResult(value);
    } else {
      setCommentText(value);
    }
  };
  const isButtonDisabled =
    !commentValue.trim() && !markDownResult && !latexResult;

  const handleImageChange = (event) => {
    console.log("image added");
    const file = event.target.files[0];
    setAttachedFile(file);
    if (file) {
      extractLatexFromImage(file); // Call the API to get LaTeX
      event.target.value = null;
      setAttachedFile(null);
    }
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const extractLatexFromImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file); // Add the file to form data

    try {
      const response = await axios.post(
        "https://api.mathpix.com/v3/text",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            app_id: "moinduel_391939_069b97",
            app_key:
              "f93620f23d66894460642dc8bf4e7cd9011208557744209c13d433a709c47186",
          },
        }
      );
      console.log("response : ", response);

      let extractedText = response.data.latex_styled || response.data.text;
      console.log("extracted : ", extractedText);

      if (extractedText === response.data.text) {
        extractedText = replaceMathDelimiters(extractedText);

        console.log(extractedText);
        setMarkDownResult(extractedText);
      } else {
        console.log("in else : ", extractedText);

        setLatexResult(extractedText);
      }
    } catch (error) {
      console.error("Error extracting text:", error);
      toast.error("Failed to extract LaTeX from the image.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const replaceMathDelimiters = (text) => {
    return (
      text
        // Replace \( and \) with $
        .replace(/\\\(/g, "$")
        .replace(/\\\)/g, "$")
        // Replace \[ and \] with $$
        .replace(/\\\[/g, "$$")
        .replace(/\\\]/g, "$$")
    );
  };

  const handleLikeClick = async () => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Like/like`,
        {
          postId: competitionId,
          userId: userId,
          likeStatus: !isLiked,
        }
      );

      // If the API call is successful, toggle the like status and update the like count
      if (response.status === 200) {
        setIsLiked(!isLiked);
        setLikeCount((prevCount) => (isLiked ? prevCount - 1 : prevCount + 1)); // Adjust count based on like status
        dispatch(fetchPosts());
      }
    } catch (error) {
      console.error("Error updating like status:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleDeleteClick = async (commentId) => {
    handleMenuClose();
    console.log("competitionId : ", competitionId, "commentId : ", commentId);

    try {
      const res = await axios.delete(
        `https://moinduel.somee.com/api/Competition/DeleteComment/${competitionId}/${commentId}`
      );
      if (res.status === 200) {
        getCompetitionById(competitionId);
        dispatch(fetchCompetitions());
        toast.success("Comment deleted successfully.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      } else {
        toast.error("Comment not found.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.error("Failed to delete the post", error);
      toast.error("Comment not found.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const handleEditClick = () => {
    // Optionally reset the state to the current comment data
    // setEditSolution(comment.solution || "");
    setOpenEditDialog(true);
    // Close the menu so it doesn't stay open behind the dialog
    handleMenuClose();
  };

  const [competitionCommentId, setCompetitionCommentId] = useState("");

  const handleUpdateComment = async () => {
    try {
      const response = await axios.put(
        `https://moinduel.somee.com/api/Competition/EditComment/${competitionId}/${competitionCommentId}`,
        {
          solution: editSolution, // e.g. "string"
        }
      );
      console.log(editSolution);

      console.log("Comment updated successfully:", response.data);
      //   // Do something with the response, e.g., update UI or store
      getCompetitionById(competitionId);
      setOpenEditDialog(false);
      toast.success("Comment updated successfully.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error updating comment:", error);
      throw error; // Re-throw if you want to handle it elsewhere
    }
  };

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid
          item
          sx={{
            marginLeft: { xs: 0, md: "240px" },
            width: {
              xs: "100%",
              md: "calc(100% - 240px)",
              xl: "calc(100% - 450px)",
            },
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <div style={{ padding: "20px" }}>
              <IconButton
                onClick={() => navigate("/")}
                sx={{
                  marginBottom: 0,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Box>
                {/* Title Section */}

                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  {competitions.title}
                </Typography>
                <Box sx={{ marginBottom: "20px", textAlign: "center" }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={2}
                  >
                    {/* LaTeX Section */}
                    <Grid item>
                      {competitions.latex ? (
                        <Box sx={{ marginTop: "20px", textAlign: "center" }}>
                          <Latex formula={competitions.latex} />
                        </Box>
                      ) : (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        ></Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {competitions.markdown ? (
                        <Box sx={{ marginTop: "20px", textAlign: "center" }}>
                          <MarkdownMathRenderer
                            markdownContent={competitions.markdown}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        ></Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                {/* Post Content */}
                <Card elevation={0} sx={{ marginBottom: "20px" }}>
                  <CardContent>
                    <Typography variant="body1" paragraph>
                      {competitions.question}
                    </Typography>
                  </CardContent>
                </Card>
                {/* Interaction Section (Likes, Comments) */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ marginLeft: "5px" }}
                    ></Typography>

                    {/* Dialog for sharing platforms */}
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      fullWidth
                      maxWidth="sm"
                    >
                      <DialogTitle>
                        Share Link
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{ position: "absolute", top: 8, right: 8 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <p>
                          Anyone who has this link will be able to view this.
                        </p>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <TelegramShareButton
                            url={postUrl}
                            quote={competitions.title}
                            style={{ margin: "0 10px" }}
                          >
                            <TelegramIcon size={40} round />
                          </TelegramShareButton>
                          <FacebookShareButton
                            url={postUrl}
                            quote={competitions.title}
                            style={{ margin: "0 10px" }}
                          >
                            <FacebookIcon size={40} round />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={postUrl}
                            title={competitions.title}
                            style={{ margin: "0 10px" }}
                          >
                            <TwitterIcon size={40} round />
                          </TwitterShareButton>
                          <WhatsappShareButton
                            url={postUrl}
                            title={competitions.title}
                            style={{ margin: "0 10px" }}
                          >
                            <WhatsappIcon size={40} round />
                          </WhatsappShareButton>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </Box>
                </Box>
                <Divider sx={{ marginBottom: "20px" }} />

                {/* Comment Item */}
                <Box>
                  {competitions.comments?.length === 0 ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ marginBottom: "20px" }}
                    >
                      No Comments available for this competition!
                    </Typography>
                  ) : (
                    (competitions.comments || []).map(
                      (
                        comment // Ensure post.comments is defined
                      ) => (
                        <Box
                          key={comment.commentId}
                          sx={{
                            position: "relative",
                            marginBottom: "16px",
                            display: "flex",
                            alignItems: "flex-start",
                          }}
                        >
                          {/* Avatar on the left */}
                          <Avatar
                            alt={comment.createdBy}
                            src="/path-to-avatar.jpg"
                          />

                          {/* Main content in the middle */}
                          <Box sx={{ marginLeft: "10px", flexGrow: 1 }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 600 }}
                              >
                                {comment.fullName}
                              </Typography>
                              {/* <Typography variant="body2" color="textSecondary">
                                {new Date(
                                  comment.createdDate
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                              </Typography> */}
                            </Box>

                            {/* LaTeX Text (if exists) */}
                            {comment.latex_Text && (
                              <Box
                                sx={{ marginTop: "20px", textAlign: "center" }}
                              >
                                <Latex
                                  formula={comment.latex_Text}
                                  fontSize="0.75rem"
                                />
                              </Box>
                            )}

                            {/* Markdown Text (if exists) */}
                            {comment.markdown_Text && (
                              <Box
                                sx={{ marginTop: "20px", textAlign: "center" }}
                              >
                                <MarkdownMathRenderer
                                  markdownContent={comment.markdown_Text}
                                />
                              </Box>
                            )}

                            <Typography variant="body2">
                              {comment.solution}
                            </Typography>
                          </Box>

                          {/* MoreVert icon absolutely positioned at top-right */}
                          {isAdmin && (
                            <Box
                              sx={{ position: "absolute", top: 0, right: 0 }}
                            >
                              <IconButton onClick={handleMenuOpen}>
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    setCompetitionCommentId(comment.id);
                                    handleEditClick(comment.id);
                                  }}
                                  // sx={{ color: "red" }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleDeleteClick(comment.id)}
                                  sx={{ color: "red" }}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                              <Dialog
                                open={openEditDialog}
                                onClose={() => setOpenEditDialog(false)}
                              >
                                <DialogTitle>Edit Comment</DialogTitle>
                                <DialogContent>
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Solution"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={editSolution}
                                    onChange={(e) =>
                                      setEditSolution(e.target.value)
                                    }
                                  />
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() => setOpenEditDialog(false)}
                                    color="inherit"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={handleUpdateComment}
                                    variant="contained"
                                    color="primary"
                                  >
                                    Update
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Box>
                          )}
                        </Box>
                      )
                    )
                  )}
                </Box>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default CompetitionsPosts;
