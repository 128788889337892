// reducers/competitionsReducer.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/Competition/GetCompletedCompetitions`;

// Async thunk action to fetch competitions
export const fetchCompetitions = createAsyncThunk(
  "competitions/fetchCompetitions",
  async () => {
    const response = await axios.get(apiUrl);
    return response.data;
  }
);

// Create a slice for competitions
const competitionsSlice = createSlice({
  name: "competitions", // Changed to a more appropriate name
  initialState: {
    loading: false,
    competitions: [],
    error: null, // Initialize error as null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetitions.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new fetch
      })
      .addCase(fetchCompetitions.fulfilled, (state, action) => {
        state.loading = false;
        state.competitions = action.payload;
      })
      .addCase(fetchCompetitions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch competitions"; // Provide a default error message
      });
  },
});

// Export the reducer generated by createSlice
export default competitionsSlice.reducer;
