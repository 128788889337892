import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  CssBaseline,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Tooltip,
} from "@mui/material";
import { Send, Paperclip } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import axios from "axios";
import Latex from "./Latex";
import MarkdownMathRenderer from "./MarkdownMathRenderer";

const CompetitionSubmission = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(0);
  const [isEnded, setIsEnded] = useState(false);
  const [competitionQuestion, setCompetitionQuestion] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    solution: "",
  });
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const { googleUser, customUser, loginMethod, isLoggedIn } = useSelector(
    (state) => state.user
  );
  const [durationInMinutes, setDurationInMinutes] = useState(0);
  const [competition, setCompetition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [latexResult, setLatexResult] = useState("");
  const [markDownResult, setMarkDownResult] = useState("");
  const [userId, setUserId] = useState("");

  const isAdmin =
    googleUser?.role?.includes("Admin") || customUser?.role === "Admin";

  useEffect(() => {
    console.log("calling fetch current competition");
    if (loginMethod === "google") {
      setUserId(googleUser.userId);
    } else if (loginMethod === "custom") {
      setUserId(customUser.userId);
    }

    fetchCurrentCompetition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (competition?.remainingTime > 0) {
      setTimeLeft(competition.remainingTime);
      setIsEnded(false);

      const timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 1) {
            clearInterval(timer); // Stop the timer when time reaches 0
            setIsEnded(true);
            fetchComments(); // Fetch comments when timer ends
            return 0;
          }
          return prevTimeLeft - 1; // Decrease time by 1 second
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup the timer on competition change or component unmount
    } else {
      setTimeLeft(0);
      setIsEnded(true);
      fetchComments(); // Fetch comments if the competition is already ended
    }
  }, [competition?.remainingTime]);

  const handleImageChange = (e) => {
    console.log("file attached");

    const file = e.target.files[0];
    if (file) {
      fetchLatexFromImage(file);
      //call latex api here.
    }
  };

  const fetchLatexFromImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        "https://api.mathpix.com/v3/text",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            app_id: "moinduel_391939_069b97",
            app_key:
              "f93620f23d66894460642dc8bf4e7cd9011208557744209c13d433a709c47186",
          },
        }
      );

      // Set result (response)
      console.log(response.data);
      let extractedText = response.data.latex_styled || response.data.text;

      console.log("extracted text : ", extractedText);

      if (extractedText === response.data.text) {
        extractedText = replaceMathDelimiters(extractedText);
        setMarkDownResult(extractedText);
      } else {
        setLatexResult(extractedText);
      }
    } catch (error) {
      console.error("Error extracting text:", error);
      toast.error("Failed to extract LaTeX from the image.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } finally {
    }
  };

  const replaceMathDelimiters = (text) => {
    return (
      text
        // Replace \( and \) with $
        .replace(/\\\(/g, "$")
        .replace(/\\\)/g, "$")
        // Replace \[ and \] with $$
        .replace(/\\\[/g, "$$")
        .replace(/\\\]/g, "$$")
    );
  };

  const fetchCurrentCompetition = async () => {
    try {
      const response = await axios.get(
        "https://moinduel.somee.com/api/Competition/GetCurrentCompetition"
      );
      console.log("fetching competition data : ", response.data);

      if (response.data.remainingTime > 0) {
        setTimeLeft(response.data.remainingTime);
        setIsEnded(false);
      } else {
        setIsEnded(true);
      }
      setCompetition(response.data);
      if (response.data.remainingTime <= 0) {
      }
    } catch (err) {
      console.log("competition error : ", err.response.data);
      setError(err.response.data);
      setCompetition(null);
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleQuestionChange = (e) => {
    setCompetitionQuestion(e.target.value);
  };

  const handleQuestionSubmit = async () => {
    if (!competitionQuestion.trim()) {
      toast.error("Competition question cannot be empty.");
      return;
    }

    try {
      const payload = {
        title: "Competition Title", // Replace with a dynamic title if needed
        question: competitionQuestion.trim(),
        markdown: markDownResult,
        latex_Text: latexResult,
        durationInMinutes: parseInt(durationInMinutes, 10),
      };

      console.log(payload);
      const response = await axios.post(
        "https://moinduel.somee.com/api/Competition/PostCompetition",
        payload
      );

      if (response.status === 200) {
        toast.success("Competition question posted successfully!", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        fetchCurrentCompetition();
      } else {
        throw new Error(`Failed with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error posting competition question:", error);
      toast.error("Failed to post competition question. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (isEnded) return;

    try {
      const response = await axios.post(
        "https://moinduel.somee.com/api/Competition/PostComment",
        {
          competitionId: competition.competitionId,
          userId: userId,
          fullName: formData.name,
          email: formData.email,
          solution: formData.solution,
          latex_Text: latexResult,
          markdown_Text: markDownResult,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        setShowThankYouDialog(true);
        setFormData({
          competitionId: "",
          userId: "",
          name: "",
          email: "",
          solution: "",
        });
        setMarkDownResult("");
        setLatexResult("");
        toast.success("Your solution submitted successfully!", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.message || "You have already submitted your solution."
        );
      }
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `https://moinduel.somee.com/api/Competition/GetComments/${competition.competitionId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        setComments(data.comments || []);
      } else {
        throw new Error(response.data.message || "Failed to fetch comments");
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleCloseDialog = () => {
    setShowThankYouDialog(false);
  };

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid
          item
          sx={{
            marginLeft: { xs: 0, md: "240px" },
            width: {
              xs: "100%",
              md: "calc(100% - 240px)",
              xl: "calc(100% - 450px)",
            },
            position: "relative",
          }}
        >
          {!isEnded && (
            <Box
              sx={{
                position: "absolute",
                top: 40,
                right: 40,
                backgroundColor: "#0F172A",
                color: "white",
                padding: "12px 24px",
                borderRadius: "8px",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {timeLeft > 0 ? formatTime(timeLeft) : "00:00:00"}
            </Box>
          )}

          <Box sx={{ padding: "40px" }}>
            {isAdmin && isEnded ? (
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  mb: 4,
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  backgroundColor: "#f8fafc",
                }}
              >
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Post Competition Question
                </Typography>
                <TextField
                  fullWidth
                  label="Competition Question"
                  value={competitionQuestion}
                  onChange={handleQuestionChange}
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  label="Duration (minutes)"
                  type="number"
                  value={durationInMinutes}
                  onChange={(e) => setDurationInMinutes(e.target.value)}
                  sx={{ mb: 3 }}
                />

                <Box sx={{ marginTop: "2px", textAlign: "center" }}>
                  <Latex formula={latexResult} />
                  <MarkdownMathRenderer markdownContent={markDownResult} />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "16px",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{
                      backgroundColor: "#f1f1f1",
                      color: "#333",
                      padding: "8px 16px",
                      borderRadius: "8px",
                      textTransform: "none",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                    }}
                  >
                    Attach Image
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleImageChange(e)}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleQuestionSubmit}
                    sx={{
                      backgroundColor: "#0F172A",
                      color: "white",
                      padding: "8px 16px",
                      borderRadius: "8px",
                      textTransform: "none",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#1E293B",
                      },
                    }}
                  >
                    Post Question
                  </Button>
                </Box>
              </Paper>
            ) : null}
            {isEnded && (
              <Alert
                severity="error"
                sx={{
                  mb: 4,
                  borderRadius: "8px",
                  "& .MuiAlert-message": {
                    fontSize: "1.1rem",
                  },
                }}
              >
                Competition has ended. Submissions are no longer accepted.
              </Alert>
            )}
            <Paper
              elevation={0}
              sx={{
                p: 4,
                mb: 4,
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                backgroundColor: "#f8fafc",
              }}
            >
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Competition Question
              </Typography>
              <Typography variant="body1">
                Solve the following mathematical problem:
                {competition && competition.question ? (
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: 1.5,
                      mb: 2,
                      color: "#1a1a1a",
                    }}
                  >
                    {competition.question}
                  </Typography>
                ) : null}
                {competition && competition.latex ? (
                  <Box sx={{ textAlign: "center" }}>
                    <Latex formula={competition.latex} />
                  </Box>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  ></Typography>
                )}
                {competition && competition.markdown ? (
                  <Box sx={{ textAlign: "center" }}>
                    <MarkdownMathRenderer
                      markdownContent={competition.markdown}
                    />
                  </Box>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  ></Typography>
                )}
              </Typography>
            </Paper>
            {!isEnded ? (
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Submit Your Solution
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    name="name"
                    label="Full Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    disabled={isEnded}
                    sx={{ mb: 3 }}
                  />

                  <TextField
                    fullWidth
                    name="email"
                    type="email"
                    label="Email Address"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    disabled={isEnded}
                    sx={{ mb: 3 }}
                  />

                  <TextField
                    fullWidth
                    name="solution"
                    label="Your Solution"
                    multiline
                    rows={6}
                    required
                    value={formData.solution}
                    onChange={handleInputChange}
                    placeholder="Type or paste your solution here..."
                    disabled={isEnded}
                    sx={{ mb: 4 }}
                  />

                  <div style={{ display: "flex", gap: "12px" }}>
                    <input
                      type="file"
                      id="attachment"
                      style={{ display: "none" }}
                      disabled={!isLoggedIn || isEnded}
                    />
                    <label htmlFor="attachment">
                      <Button
                        variant="outlined"
                        component="label"
                        sx={{
                          backgroundColor: "#f1f1f1",
                          color: "#333",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          textTransform: "none",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#e0e0e0",
                          },
                        }}
                      >
                        Attach Image
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={(e) => handleImageChange(e)}
                        />
                      </Button>
                    </label>

                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!isLoggedIn || isEnded}
                      sx={{
                        backgroundColor: "#0F172A",
                        "&:hover": {
                          backgroundColor: "#1E293B",
                        },
                      }}
                      startIcon={<Send size={20} />}
                    >
                      {!isLoggedIn ? (
                        <Tooltip title="Login to submit the solution" arrow>
                          <span>Submit Solution</span>
                        </Tooltip>
                      ) : (
                        "Submit Solution"
                      )}
                    </Button>
                  </div>
                </form>
              </Paper>
            ) : (
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Comments
                </Typography>

                <Box
                  sx={{
                    maxHeight: "300px", // Limit the height to make it scrollable
                    overflowY: "auto", // Add vertical scrolling
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "16px",
                    backgroundColor: "#f9fafb",
                  }}
                >
                  {comments && comments.length > 0 ? (
                    <ul
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      {comments.map((comment) => (
                        <li
                          key={comment.id}
                          style={{
                            marginBottom: "16px",
                            border: "1px solid #e0e0e0",
                            borderRadius: "8px",
                            padding: "16px",
                            backgroundColor: "#ffffff",
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "16px",
                          }}
                        >
                          {/* Profile Image */}
                          <img
                            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                              comment.fullName
                            )}&background=random&size=50`}
                            alt={comment.fullName}
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />

                          {/* Comment Content */}
                          <div>
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              sx={{ color: "#0F172A" }}
                            >
                              {comment.userName}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ margin: "8px 0", color: "#4B5563" }}
                            >
                              {comment.solution}
                              <Box sx={{ textAlign: "center" }}>
                                <Latex formula={comment.latex_Text} />
                                <MarkdownMathRenderer
                                  markdownContent={comment.markdown_Text}
                                />
                              </Box>
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{ color: "#9CA3AF" }}
                            >
                              {new Date().toLocaleString()}
                            </Typography>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      No comments to show.
                    </Typography>
                  )}
                </Box>
              </Paper>
            )}
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={showThankYouDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle>Submission Received!</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary">
            Thank you for participating in the mathematics competition. Your
            solution has been successfully submitted.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              backgroundColor: "#0F172A",
              color: "white",
              "&:hover": {
                backgroundColor: "#1E293B",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompetitionSubmission;
