// store.js
import { configureStore } from "@reduxjs/toolkit";
import postReducer from "./reducers/postReducer"; // Adjust the path as needed
import userReducer from "./reducers/loginReducer";
import commentReducer from "./reducers/commentReducer";
import allUserReducer from "./reducers/usersSlice";
import postByIdReducer from "./reducers/postByIdSlice";
import competitionsReducer from "./reducers/recentCompetitionsReducer";

const store = configureStore({
  reducer: {
    user: userReducer,
    posts: postReducer,
    competitions: competitionsReducer,
    comments: commentReducer,
    getUsers: allUserReducer,
    postById: postByIdReducer,
  },
});

export default store;
