import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Typography,
  Paper,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Menu,
  MenuItem,
  Alert,
} from "@mui/material";
import { Brain, Puzzle, ArrowRight } from "lucide-react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ShareIcon from "@mui/icons-material/Share";
import CommentIcon from "@mui/icons-material/Comment";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchPosts } from "../redux/reducers/postReducer";
import { fetchCompetitions } from "../redux/reducers/recentCompetitionsReducer";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { toast } from "react-toastify";
import {
  loginWithCustomEmail,
  loginWithGoogle,
  logoutUser,
} from "../redux/actions/loginActions";
import { jwtDecode } from "jwt-decode";

const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { posts } = useSelector((state) => state.posts);
  const { competitions } = useSelector((state) => state.competitions);
  const [visiblePosts, setVisiblePosts] = useState(
    window.innerWidth < 960 ? 15 : 11
  );
  const [visibleCompetitions, setVisibleCompetitions] = useState(
    window.innerWidth < 960 ? 4 : 4
  );
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [userId, setUserId] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);

  const { isLoggedIn, loginMethod, googleUser, customUser } = useSelector(
    (state) => state.user
  );

  const isAdmin =
    // Handle googleUser.role (can be array or string)
    (googleUser?.role &&
      (Array.isArray(googleUser.role)
        ? googleUser.role.includes("Admin")
        : googleUser.role === "Admin")) ||
    // Handle customUser.role (can be array or string)
    (customUser?.role &&
      (Array.isArray(customUser.role)
        ? customUser.role.includes("Admin")
        : customUser.role === "Admin"));

  useEffect(() => {
    if (loginMethod === "google") {
      setUserId(googleUser.userId);
    } else if (loginMethod === "custom") {
      setUserId(customUser.userId);
    }
  }, [customUser.userId, googleUser.userId, loginMethod]);

  useEffect(() => {
    const checkToken = () => {
      try {
        const serviceToken = getAuthData();
        const userData = jwtDecode(serviceToken.token);

        console.log(userData);

        if (serviceToken.token && verifyToken(serviceToken.token)) {
          if (serviceToken.loginMethod === "custom") {
            const customLogin = {
              userId: userData.userId,
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              role: userData.role,
              pricePlan: userData.pricePlan,
              perDayLimit: userData.perDayLimit,
              remaningApiCalls: userData.remaningApiCalls,
            };
            dispatch(loginWithCustomEmail(customLogin));
          } else {
            const googleUser = {
              userId: serviceToken.data.userId,
              firstName: serviceToken.data.firstName,
              lastName: serviceToken.data.lastName,
              email: serviceToken.data.email,
              role: serviceToken.data.role,
              pricePlan: serviceToken.data.pricePlan,
              perDayLimit: serviceToken.data.perDayLimit,
              remaningApiCalls: serviceToken.data.remaningApiCalls,
            };
            dispatch(loginWithGoogle(googleUser));
          }
        }
      } catch (error) {
        localStorage.removeItem("serviceToken");
        dispatch(logoutUser());
      }
    };

    checkToken();
  }, [dispatch, navigate]);

  const getAuthData = () => {
    // Get the stored auth data
    const authDataString = localStorage.getItem("serviceToken");

    // Parse it if it exists, or return null if not
    return authDataString ? JSON.parse(authDataString) : null;
  };

  const verifyToken = (serviceToken) => {
    if (!serviceToken) {
      return false;
    }
    const decoded = jwtDecode(serviceToken);

    return decoded.exp > Date.now() / 1000;
  };

  const handlePostClick = (post) => {
    navigate(`/post/${post.postId}`);
  };

  const handleCompetitionClick = (post) => {
    navigate(`/competition/${post.competitionId}`);
  };

  const sortedPosts = [...posts].sort((a, b) => {
    return new Date(b.createdDate) - new Date(a.createdDate);
  });

  const handleCreatePost = () => {
    if (isLoggedIn) navigate("/create-post");
    else navigate("/login");
  };

  const handleLoadMore = () => {
    setVisiblePosts((prevVisible) => prevVisible + 11);
  };

  const handleLoadMoreCompetitions = () => {
    setVisibleCompetitions((prevVisible) => prevVisible + 4);
  };

  const handleShareClick = (event, post) => {
    event.stopPropagation();
    setSelectedPost(post);
    setShareDialogOpen(true);
  };

  const handleCopyLink = (postId) => {
    const link = `${window.location.origin}/post/${postId}`;
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 3000);
  };

  const handleLikeClick = async (event, post) => {
    event.stopPropagation();
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Like/like`,
        {
          postId: post.postId,
          userId: userId,
          likeStatus: !post.likes.some(
            (like) => like.userId === userId && like.likeStatus
          ),
        }
      );

      if (response.status === 200) {
        dispatch(fetchPosts());
      }
    } catch (error) {
      console.error("Error updating like status:", error);
    }
  };

  const TrendingPostCard = ({ post }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const likeCount = post.likes.filter(
      (like) => like.likeStatus === true
    ).length;
    const commentCount = post.comments ? post.comments.length : 0;
    const isLiked = post.likes.some(
      (like) => like.userId === userId && like.likeStatus
    );

    const handleMenuOpen = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleEditClick = async (postId) => {
      navigate(`/edit/${post.postId}`);
    };

    const handleDeleteClick = async (postId) => {
      handleMenuClose();
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/Posts/Delete/${postId}`
        );
        if (res.status === 200) {
          dispatch(fetchPosts());
          toast.success("Post deleted successfully.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          toast.error("Post not found.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      } catch (error) {
        console.error("Failed to delete the post", error);
        toast.error("Post not found.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    };

    const truncateContent = (content, maxWords) => {
      const words = content.split(" ");
      return words.length > maxWords
        ? words.slice(0, maxWords).join(" ") + "..."
        : content;
    };

    return (
      <Box
        sx={{
          display: "flex",
          p: 2,
          cursor: "pointer",
          "&:hover": { bgcolor: "rgba(0, 0, 0, 0.02)" },
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
          position: "relative",
        }}
      >
        {/* Post content */}
        <Box sx={{ flex: 1 }} onClick={() => handlePostClick(post)}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Avatar
              src={post.authorImage || "/default-avatar.png"}
              sx={{ width: 24, height: 24, mr: 1 }}
            />
            <Typography variant="body2" sx={{ mr: 1 }}>
              {post.createdBy}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {new Date(post.createdDate).toLocaleDateString()}
            </Typography>
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {post.title}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              mb: 1,
              color: "lightgrey",
              fontSize: "0.875rem",
            }}
          >
            {truncateContent(post.content, 15)}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="small"
                onClick={(e) => handleLikeClick(e, post)}
                color={isLiked ? "primary" : "default"}
                sx={{ p: 0, mr: 0.5 }}
              >
                <ThumbUpIcon sx={{ fontSize: 16 }} />
              </IconButton>
              <Typography variant="caption">{likeCount}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CommentIcon sx={{ fontSize: 16, mr: 0.5 }} />
              <Typography variant="caption">{commentCount}</Typography>
            </Box>
            <IconButton
              size="small"
              onClick={(e) => handleShareClick(e, post)}
              sx={{ p: 0 }}
            >
              <ShareIcon sx={{ fontSize: 16 }} />
            </IconButton>

            {isAdmin && (
              <>
                <IconButton
                  aria-label="more"
                  onClick={handleMenuOpen}
                  sx={{ p: 0 }}
                >
                  <MoreVertIcon sx={{ fontSize: 16 }} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick(post.postId);
                    }}
                    sx={{ color: "#000" }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(post.postId);
                    }}
                    sx={{ color: "red" }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
            {!isAdmin && userId === post.userId ? (
              <>
                <IconButton
                  aria-label="more"
                  onClick={handleMenuOpen}
                  sx={{ p: 0 }}
                >
                  <MoreVertIcon sx={{ fontSize: 16 }} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick(post.postId);
                    }}
                    sx={{ color: "#000" }}
                  >
                    Edit
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </Box>
        </Box>

        {/* Image (if available) */}
        {post.imagePath && (
          <Box sx={{ ml: 2, width: 100, flexShrink: 0 }}>
            <img
              src={`https://moinduel.somee.com/${post.imagePath}`}
              alt={post.title}
              style={{
                width: "100%",
                height: "60px",
                objectFit: "cover",
                borderRadius: "4px",
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const CompetitionPostCard = ({ post }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const commentCount = post.comments ? post.comments.length : 0;

    const handleMenuOpen = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleDeleteClick = async (competitionId) => {
      handleMenuClose();
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/Competition/DeleteCompetition/${competitionId}`
        );
        if (res.status === 200) {
          dispatch(fetchCompetitions());
          toast.success("Post deleted successfully.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          toast.error("Post not found.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      } catch (error) {
        console.error("Failed to delete the post", error);
        toast.error("Post not found.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    };

    const truncateContent = (content, maxWords) => {
      const words = content.split(" ");
      return words.length > maxWords
        ? words.slice(0, maxWords).join(" ") + "..."
        : content;
    };

    return (
      <Box
        sx={{
          p: 2,
          cursor: "pointer",
          "&:hover": { bgcolor: "rgba(0, 0, 0, 0.02)" },
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box
          onClick={() => {
            handleCompetitionClick(post);
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
            <Avatar
              src={post.authorImage || "/default-avatar.png"}
              sx={{ width: 32, height: 32, mr: 1.5 }}
            />
            <Box>
              <Typography variant="subtitle2">{post.createdBy}</Typography>
              <Typography variant="caption" color="text.secondary">
                {new Date(post.startTime).toLocaleDateString()}
                {" - "}
                {new Date(post.endTime).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              mb: "5px",
              lineHeight: 1.4,
            }}
          >
            {post.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mb: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {truncateContent(post.question, 15)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
            color: "text.secondary",
          }}
        >
          <IconButton
            size="small"
            onClick={() => handlePostClick(post)}
            sx={{ p: 0 }}
          >
            <CommentIcon sx={{ fontSize: 18 }} />
            <Typography variant="caption" sx={{ ml: 0.5 }}>
              {commentCount}
            </Typography>
          </IconButton>

          <IconButton
            size="small"
            onClick={(e) => handleShareClick(e, post)}
            sx={{ p: 0 }}
          >
            <ShareIcon sx={{ fontSize: 18 }} />
          </IconButton>
          {isAdmin && (
            <>
              <IconButton
                aria-label="more"
                onClick={handleMenuOpen}
                sx={{ p: 0 }}
              >
                <MoreVertIcon sx={{ fontSize: 16 }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(post.competitionId);
                  }}
                  sx={{ color: "red" }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const RegularPostCard = ({ post }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const likeCount = post.likes.filter(
      (like) => like.likeStatus === true
    ).length;
    const commentCount = post.comments ? post.comments.length : 0;
    const isLiked = post.likes.some(
      (like) => like.userId === userId && like.likeStatus
    );

    const handleMenuOpen = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleDeleteClick = async (postId) => {
      handleMenuClose();
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/Posts/Delete/${postId}`
        );
        if (res.status === 200) {
          dispatch(fetchPosts());
          toast.success("Post deleted successfully.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          toast.error("Post not found.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      } catch (error) {
        console.error("Failed to delete the post", error);
        toast.error("Post not found.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    };

    const truncateContent = (content, maxWords) => {
      const words = content.split(" ");
      return words.length > maxWords
        ? words.slice(0, maxWords).join(" ") + "..."
        : content;
    };

    return (
      <Box
        sx={{
          p: 2,
          cursor: "pointer",
          "&:hover": { bgcolor: "rgba(0, 0, 0, 0.02)" },
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box onClick={() => handlePostClick(post)}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
            <Avatar
              src={post.authorImage || "/default-avatar.png"}
              sx={{ width: 32, height: 32, mr: 1.5 }}
            />
            <Box>
              <Typography variant="subtitle2">{post.createdBy}</Typography>
              <Typography variant="caption" color="text.secondary">
                {new Date(post.createdDate).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              mb: "5px",
              lineHeight: 1.4,
            }}
          >
            {post.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mb: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {truncateContent(post.content, 15)}
          </Typography>

          {post.imagePath && (
            <Box
              sx={{
                width: "100%",
                mb: 2,
                borderRadius: 1,
                overflow: "hidden",
              }}
            >
              <img
                src={`https://moinduel.somee.com/${post.imagePath}`}
                alt={post.title}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "300px",
                  objectFit: "cover",
                }}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
            color: "text.secondary",
          }}
        >
          <IconButton
            size="small"
            onClick={(e) => handleLikeClick(e, post)}
            color={isLiked ? "primary" : "default"}
            sx={{ p: 0 }}
          >
            <ThumbUpIcon sx={{ fontSize: 18 }} />
            <Typography variant="caption" sx={{ ml: 0.5 }}>
              {likeCount}
            </Typography>
          </IconButton>

          <IconButton
            size="small"
            onClick={() => handlePostClick(post)}
            sx={{ p: 0 }}
          >
            <CommentIcon sx={{ fontSize: 18 }} />
            <Typography variant="caption" sx={{ ml: 0.5 }}>
              {commentCount}
            </Typography>
          </IconButton>

          <IconButton
            size="small"
            onClick={(e) => handleShareClick(e, post)}
            sx={{ p: 0 }}
          >
            <ShareIcon sx={{ fontSize: 18 }} />
          </IconButton>
          {isAdmin && (
            <>
              <IconButton
                aria-label="more"
                onClick={handleMenuOpen}
                sx={{ p: 0 }}
              >
                <MoreVertIcon sx={{ fontSize: 16 }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(post.postId);
                  }}
                  sx={{ color: "red" }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid
          item
          sx={{
            marginLeft: { xs: 0, md: "240px" },
            width: { xs: "100%", md: "calc(100% - 240px)" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 3,
            pl: { md: 4 },
          }}
        >
          <Box
            sx={{
              flex: 1,
              padding: "16px",
              maxWidth: {
                xs: "100%",
                md: "100%",
                xl: "calc(100% - 450px)", // Only subtract space when promo box is visible
              },
            }}
          >
            <Box
              display="flex"
              sx={{
                border: "1px solid #00000014",
                padding: "1rem",
                borderRadius: "10px",
                mb: 3,
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1">
                Join us and share your experience!
              </Typography>
              <Button
                variant="contained"
                onClick={handleCreatePost}
                sx={{
                  bgcolor: "#0F172A",
                  textTransform: "none",
                  "&:hover": { bgcolor: "#1e293b" },
                }}
              >
                Post
              </Button>
            </Box>

            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Trending This Week
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
              Most engaging discussions of the week
            </Typography>
            <Box
              sx={{
                mb: 4,
                bgcolor: "white",
                borderRadius: 1,
                overflow: "hidden",
              }}
            >
              {posts.slice(0, 5).map((post) => (
                <TrendingPostCard key={post.postId} post={post} />
              ))}
            </Box>

            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Competitions
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
              Recent competitions
            </Typography>
            <Box sx={{ bgcolor: "white", borderRadius: 1, overflow: "hidden" }}>
              {competitions.slice(0, visibleCompetitions).map((post) => (
                <CompetitionPostCard
                  key={competitions.competitionId}
                  post={post}
                />
              ))}
            </Box>

            {visibleCompetitions < posts.length && (
              <Box sx={{ textAlign: "center", mt: 3, mb: { xs: 8, md: 2 } }}>
                <Button
                  variant="contained"
                  onClick={handleLoadMoreCompetitions}
                  sx={{
                    bgcolor: "#0F172A",
                    textTransform: "none",
                    "&:hover": { bgcolor: "#1e293b" },
                  }}
                >
                  Load More
                </Button>
              </Box>
            )}

            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Latest Posts
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
              Recent community discussions
            </Typography>
            <Box sx={{ bgcolor: "white", borderRadius: 1, overflow: "hidden" }}>
              {sortedPosts.slice(0, visiblePosts).map((post) => (
                <RegularPostCard key={post.postId} post={post} />
              ))}
            </Box>

            {visiblePosts < posts.length && (
              <Box sx={{ textAlign: "center", mt: 3, mb: { xs: 8, md: 2 } }}>
                <Button
                  variant="contained"
                  onClick={handleLoadMore}
                  sx={{
                    bgcolor: "#0F172A",
                    textTransform: "none",
                    "&:hover": { bgcolor: "#1e293b" },
                  }}
                >
                  Load More
                </Button>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: { xs: "100%", md: "300px" },
              position: { xs: "static", md: "sticky" },
              top: "80px",
              height: "fit-content",
              padding: "16px",
              order: { xs: 1, md: 0 },
              display: { xs: "none", xl: "block" },
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 2,
                borderRadius: "12px",
                border: "1px solid #e0e0e0",
                background:
                  "linear-gradient(135deg, rgba(80, 200, 120, 0.05) 0%, rgba(74, 144, 226, 0.05) 100%)",
                cursor: "pointer",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                  "& .arrow-icon": {
                    transform: "translateX(4px)",
                  },
                },
              }}
              onClick={() => navigate("/learn")}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Brain size={24} color="#50C878" />
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  sx={{ ml: 1 }}
                >
                  Learning Hub
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Enhance your mathematical skills with personalized learning
                paths and interactive challenges.
              </Typography>
              <Box
                sx={{ display: "flex", alignItems: "center", color: "#50C878" }}
              >
                <Typography variant="body2" sx={{ mr: 1 }}>
                  Start Learning
                </Typography>
                <ArrowRight
                  size={16}
                  className="arrow-icon"
                  sx={{ transition: "transform 0.2s" }}
                />
              </Box>
            </Paper>

            <Paper
              elevation={0}
              sx={{
                p: 2,
                mt: 3,
                borderRadius: "12px",
                border: "1px solid #e0e0e0",
                background:
                  "linear-gradient(135deg, rgba(80, 200, 120, 0.05) 0%, rgba(74, 144, 226, 0.05) 100%)",
                cursor: "pointer",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                  "& .arrow-icon": {
                    transform: "translateX(4px)",
                  },
                },
              }}
              onClick={() => navigate("/puzzles")}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Puzzle size={24} color="#50C878" />
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  sx={{ ml: 1 }}
                >
                  Daily Puzzles
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Challenge yourself with our daily mathematical puzzles and
                compete with others.
              </Typography>
              <Box
                sx={{ display: "flex", alignItems: "center", color: "#50C878" }}
              >
                <Typography variant="body2" sx={{ mr: 1 }}>
                  Try Puzzles
                </Typography>
                <ArrowRight
                  size={16}
                  className="arrow-icon"
                  sx={{ transition: "transform 0.2s" }}
                />
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      {/* Share Dialog */}
      <Dialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          Share Post
          <IconButton
            aria-label="close"
            onClick={() => setShareDialogOpen(false)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedPost && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  mb: 3,
                }}
              >
                <TelegramShareButton
                  url={`${window.location.origin}/post/${selectedPost.postId}`}
                >
                  <TelegramIcon size={40} round />
                </TelegramShareButton>
                <FacebookShareButton
                  url={`${window.location.origin}/post/${selectedPost.postId}`}
                >
                  <FacebookIcon size={40} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`${window.location.origin}/post/${selectedPost.postId}`}
                >
                  <TwitterIcon size={40} round />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={`${window.location.origin}/post/${selectedPost.postId}`}
                >
                  <WhatsappIcon size={40} round />
                </WhatsappShareButton>
              </Box>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<ContentCopyIcon />}
                onClick={() => handleCopyLink(selectedPost.postId)}
                sx={{
                  textTransform: "none",
                  borderColor: "#0F172A",
                  color: "#0F172A",
                  "&:hover": {
                    borderColor: "#1e293b",
                    backgroundColor: "rgba(15, 23, 42, 0.04)",
                  },
                }}
              >
                Copy Link
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* Success Snackbar for Copy Link */}
      <Snackbar
        open={copySuccess}
        autoHideDuration={3000}
        onClose={() => setCopySuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setCopySuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Layout;
